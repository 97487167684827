<template>
    <div class="index">
          <a-form layout="inline">
            <a-form-item label="设备编号" ><a-input placeholder="输入设备编号" v-model="params.deviceCode" allowClear @pressEnter="onSearch" @change="onSearch"/></a-form-item>
            <a-form-item label="归集点名称" ><a-input placeholder="输入归集点名称" v-model="params.centerName" allowClear @pressEnter="onSearch" @change="onSearch"/></a-form-item>
            <a-form-item label="行政区划" style="width: 220px">
                <a-select  @change="handleChangeRegion" style="width: 120px;" allowClear>
                  <a-select-option :value="item.regionCode" v-for="(item, index) in regions" :key="index">
                      {{item.regionName}}
                  </a-select-option>
            </a-select>
            </a-form-item>
            <a-form-item label="行政街道" style="width: 220px">
                <a-select  @change="handleChangeStreet" style="width: 120px;" allowClear>
                  <a-select-option :value="item.regionCode" v-for="(item, index) in streets" :key="index">
                      {{item.regionName}}
                  </a-select-option>
            </a-select>
            </a-form-item>
            <a-form-item label="选择时间">
                <a-range-picker  v-model="selectTime" @change="changeTime" @ok="changeTime" allowClear>
                <template slot="renderExtraFooter">
                    其他
                </template>
            </a-range-picker>
            </a-form-item>
            <a-form-item style="float:right;">
              <a-button type="primary" @click="onSearch">  查询 </a-button>
              <a-button type="default" @click="onReset" style="margin-left: 10px;" >  重置 </a-button>
                 <a-popconfirm
                        title="确定删除?"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="confirmDelete('multiple')"
                        @cancel="cancelDelete"
                    >
                        <a-button type="danger" style="margin-left: 10px;" >  批量删除 </a-button>
                  </a-popconfirm>
                  <a-popconfirm
                        title="确定操作?"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="confirmReload"
                    >
                        <a-button type="default" style="margin-left: 10px;" >  推送小程序数据 </a-button>
                  </a-popconfirm>
             
            </a-form-item>
        </a-form>
        <download-excel :data="json_data" :fields="json_fields" :name="dwtjsjName" type="xls" worksheet="实时数据" :before-generate="exportActualExcel">
            <a-button type="primary" style="margin-left: 10px;margin-top: 30px;"    :loading="exportLoading">  导出查询结果 </a-button>
        </download-excel>
        <a-table size="middle" bordered :scroll="{  y: 570 }" :columns="columns" :data-source="tableData" style="margin-top: 20px;" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"  :loading="loading" :pagination="pagination" >
            <template slot-scope="text, record" slot="image" >
                <img :src="record.image" class="actual-img" v-if="record.image" @click="showPreview(record.image)"/>
                <p v-else style="text-align: center;margin-bottom:0px;">无</p>
            </template>
            <span slot="action" slot-scope="text, record">
                  <a-popconfirm
                        title="确定删除?"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="confirmDelete('single', record)"
                        @cancel="cancelDelete"
                    >
                        <a>删除</a>
                    </a-popconfirm>
            </span>
        </a-table>
        <a-modal   v-if="visible" :visible="visible" :footer="null" width="900px" @cancel="handleCancel" style="text-align:center;" :mask="false" class="preview-image-modal">
             <img :src="previewImage" style="width: 90%;height:80%;"/>
        </a-modal>
    </div>
</template>

<script>
import obj from './dataStructure'
import moment  from 'moment'
import { queryComprehensiveDataApi, deleteComprehensiveDataApi, reloadCollectApi } from '@/api/actual'
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const columns = obj.columns
const tableData = [
  {
    id: '1',
    name: '采集点1',
    division: ['苏州市', '吴中区']
  }
];
export default {
  name: 'comprehensive',
  data() {
    return {
      exportLoading: false,
      json_fields: {
        归集点: "centerName",
        清洁屋: "houseName",
        重量: "weight",
        垃圾类别: "typeName",
        设备编号: "deviceCode",
        垃圾桶: "trashCode",
        采集时间: "collectTime"
      },
      json_data: [], //导出表格数据
      dwtjsjName: "实时数据",
      previewImage: '',
      visible: false,
      tableDataBak: [],
      streets: [],
      regions: [],
      confirmLoading: false,
      showModal: false,
      params: {},
      loading: false,
      selectedRowKeys: [],
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total) => {
          return '共' + total + '条'
        },
        pageSizeOptions: ['10', '20', '30', '40', '50', '60', '70'],
        onChange: (current, size) => this.changePage(current, size),
        onShowSizeChange:(current, pageSize)=>this.changePage(current, pageSize),
      },
       rowSelection,
      value: [],
      tableData,
      columns,
      selectTime: [moment(), moment()]
    };
  },
  mounted() {
    this.$getRegionNodeChild('').then(res => {
      this.regions = res
    })
    this.params.startTime = this.selectTime[0]
    this.params.endTime = this.selectTime[1]
    this.queryComprehensiveData()
  },
  methods: {
    async exportActualExcel() {
      this.exportLoading = true
      this.dwtjsjName = this.params.centerName || '实时数据'
      await queryComprehensiveDataApi({...this.params, page: 1, limit: 20000000}).then(res => {
            this.json_data = res.result.content
            this.json_data.forEach(item => {
                item.collectTime = moment(item.collectTime).format('YYYY-MM-DD HH:mm:ss')
            })
            this.exportLoading = false
        }).catch(() => {
          this.exportLoading = false
        })
    },
    showPreview(image){
      this.visible = true
      this.previewImage = image
    },
    handleCancel() {
      this.visible = false;
    },
    handleOK() {
      this.visible = false;
    },
    confirmReload() {
      reloadCollectApi().then(res => {
          this.$message.success('推送成功')
      })
    },
    changeSize(e) {
      console.log(e)
    },
      changeTime(e) {
          this.params.startTime = e[0]
          this.params.endTime = e[1]
          this.queryComprehensiveData()
      },
      onReset() {
          window.location.reload()
      },
      cancelDelete() {
        this.selectedRowKeys = []
      },
      confirmDelete(type, data) {
          let ids = []
          if(type == 'multiple') {
              this.selectedRowKeys.forEach(item => {
                  ids.push(this.tableData[item].id)
              })
          } else {
              ids.push(data.id)
          }
          this.deleteByIds(ids)
      },
      deleteByIds(ids) {
        console.log('要删除的项' + ids)
        deleteComprehensiveDataApi(ids).then(res => {
            this.$message.success('删除成功')
            this.pagination.current = 1
            this.pagination.total = 0
            this.queryComprehensiveData()
            this.selectedRowKeys = []
        })
        .catch(() => {
            this.$message.error('删除失败')
        })
      },
    async queryComprehensiveData() {
        this.loading = true
        this.params.page = this.pagination.current
        this.params.limit = this.pagination.pageSize
         queryComprehensiveDataApi({...this.params}).then(res => {
            this.loading = false
            this.tableData = res.result.content
            this.tableData.forEach(item => {
                item.collectTime = moment(item.collectTime).format('YYYY-MM-DD HH:mm:ss')
            })
            this.pagination.current = res.result.pageNo + 1
            this.pagination.total = res.result.totalElements
            this.params.page = this.pagination.current
        })
    },
    handleChangeRegion(e) {
      this.params.areaCode = e
      this.$getRegionNodeChild(e).then(res => {
        this.streets = res
      })
    },
    handleChangeStreet(e) {
      this.params.streetCode = e
      this.onSearch()
    },
    onSearch() {
      this.pagination.current = 1
      this.pagination.total = 0
      this.queryComprehensiveData()
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    changePage(current, pageSize) {
      this.pagination.current = current
      this.pagination.pageSize = pageSize
      this.queryComprehensiveData()
    }
  }
};
</script>

<style lang="scss">
.index{
    /* background: #F5F7F9; */
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
}
.ant-form{
    text-align: initial;
}
.actual-img{
  width: 80px;
  height: 40px;
  margin: auto;
  background: #1890ff;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.preview-image-modal{
  .ant-modal-content {
    position: relative;
    background-color: transparent;
    background-clip: padding-box;
    border: 0;
    border-radius: 4px;
    -webkit-box-shadow: 0px !important;
    box-shadow: 0 0px 0px #fff !important;
  }
}

</style>