import service from './network';


/**
 *街道首页-实时数据
 * @param {} data
 * @returns
 */
export function queryStreetActualApi(data, code) {
    return service.request({
        url: '/street/center/real/time',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

/**
 *小区首页-实时数据
 * @param {} data
 * @returns
 */
export function queryCenterActualApi(data, code) {
    return service.request({
        url: '/street/center/real/time',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

export function queryComprehensiveDataApi(data) {
    return service.request({
        url: '/garbage/collect/real/time',
        method: 'post',
        data
    })
}

export function deleteComprehensiveDataApi(data) {
    return service.request({
        url: '/garbage/collect/delete',
        method: 'delete',
        data
    })
}

/**
 *刷新小程序基础数据
 * @param {*} data
 * @returns
 */
export function reloadCollectApi(data) {
    return service.request({
        url: '/garbage/collect/reload',
        method: 'put',
        data
    })
}

/**
 *就地处置实时数据
 * @param {*} data
 * @returns
 */
export function siteCollectApi(data) {
    return service.request({
        url: '/site/garbage/collect/query',
        method: 'post',
        data
    })
}


/**
 *推送
 * @param {*} data
 * @returns
 */
export function devicePushApi(data) {
    return service.request({
        url: '/management/device/plan/imitate',
        method: 'put',
        data
    })
}

