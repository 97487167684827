export default {
    columns: [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            width: 0
        },
        {
            title: '图片',
            dataIndex: 'image',
            key: 'image',
            align: 'center',
            scopedSlots: { customRender: 'image' },
        },
        {
            title: '归集点',
            dataIndex: 'centerName',
            key: 'centerName',
            width: 250
        },
        {
            title: '清洁屋',
            dataIndex: 'houseName',
            key: 'houseName',
            width: 250
        },
        {
            title: '重量(kg)',
            dataIndex: 'weight',
            key: 'weight',
        },
        {
            title: '垃圾类别',
            dataIndex: 'typeName',
            key: 'typeName',
        },
        {
            title: '设备编号',
            dataIndex: 'deviceCode',
            key: 'deviceCode',
        },
        {
            title: '垃圾桶',
            dataIndex: 'trashCode',
            key: 'trashCode',
        },
        {
            title: '采集时间',
            dataIndex: 'collectTime',
            key: 'collectTime',
            width: 200,
            scopedSlots: { customRender: 'collectTime' },
        },
        {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 120,
            scopedSlots: { customRender: 'action' },
        }
    ],
    siteColumns: [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            width: 0
        },
        {
            title: '站点',
            dataIndex: 'villageName',
            key: 'villageName',
            width: 250
        },
        {
            title: '行进类别',
            dataIndex: 'travelType',
            key: 'travelType',
            width: 120
        },
        {
            title: '垃圾类别',
            dataIndex: 'typeName',
            key: 'typeName',
            width: 150
        },
        {
            title: '卡号',
            dataIndex: 'licensePlate',
            key: 'licensePlate',
            width: 150
        },
        {
            title: '时间',
            dataIndex: 'submitDate',
            key: 'submitDate',
            scopedSlots: { customRender: 'submitDate' },
        },
        {
            title: '毛重(kg)',
            dataIndex: 'grossWeight',
            key: 'grossWeight'
        },
        {
            title: '净重(kg)',
            dataIndex: 'netWeight',
            key: 'netWeight'
        },
        {
            title: '采集设备',
            dataIndex: 'deviceCode',
            key: 'deviceCode'
        }
    ]
}